

























import {
  defineComponent,
  PropType,
  reactive,
  ref,
  watch,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";

export default defineComponent({
  props: {
    vehicle: {
      type: Object as PropType<{ [key: string]: any }>,
      required: true,
    },
  },
  emits: ["update"],
  setup(props, { emit, root }) {
    const apiInstance = root?.$store.getters[
      "api/getInstance"
    ] as AxiosInstance;

    const loading = ref(false);

    const isOpen = ref(false);
    const isValid = ref<boolean>();

    const model = reactive({
      mileage: 0,
    });

    const rules = {
      mileage: [(v: any) => !!v || "Podaj przebieg"],
    };

    const updateModel = () => {
      model.mileage =
        (props.vehicle &&
          props.vehicle.mileages &&
          props.vehicle.mileages[0] &&
          props.vehicle.mileages[0].mileage) ||
        null;
    };

    watch(() => props.vehicle, updateModel, { deep: true, immediate: true });

    const onSubmit = async () => {
      loading.value = true;

      apiInstance
        .post(`vehicle/${props.vehicle.id}/mileage`, {
          mileage: model.mileage,
        })
        .then(() => {
          emit("update");
        })
        .catch(console.log)
        .finally(() => {
          loading.value = false;
          isOpen.value = false;
        });
    };

    return { loading, isOpen, isValid, model, rules, onSubmit };
  },
});
